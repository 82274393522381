<template>
  <div id="index"></div>
</template>

<script>
export default {
  name: "luckdraw",
  data() {
    return {
      isTra: true, //是否是横屏
      winWidth: "", //屏幕的宽高
      winHeight: "", //屏幕的宽高
      luckyWin: "600px", //转盘的宽高
      luckyHeight: "600px", //转盘的宽高
    };
  },
  created() {
    var query = this.$route.query;
    this.type = query.type;
    this.type_id = query.type_id;
    this.market_id = query.market_id;
    this.winWidth = window.screen.width;
    this.winHeight = window.screen.height;
    if (this.market_id == 1495) {
      alert("winWidth:" + this.winWidth + "winHeight:" + this.winHeight);
    }
    if (this.winWidth > this.winHeight) {
      this.$router.push({
        path:
          "/luckdraw_tra?market_id=" +
          this.market_id +
          "&type_id=" +
          this.type_id +
          "&type=" +
          this.type,
      });
    } else {
      this.$router.push({
        path:
          "/luckdraw_ver?market_id=" +
          this.market_id +
          "&type_id=" +
          this.type_id +
          "&type=" +
          this.type,
      });
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
// @import "../assets/scss/index.scss";
</style>
